import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { PageInit } from "../../../helper/PageFunction";
import { baseApi } from "../../../helper/constant";
import { message, resApi } from "../../../helper/function";

import { DataTableBtn, PageCanvas } from "../../../components/BaseElement/Page";
import { ModalButton, ModalCanvas } from "../../../components/BaseElement/Modal";
import MDBox from "../../../components/MDBox";
import FormGroup from "../../../components/BaseElement/FormData";
import DataTable from "../../../components/BaseElement/TableV7";
import DetailCode from "./DetailCode";

const tableHeader = [
    {
        Header: "No", accessor: (row, idx) => {
            return idx + 1;
        },
        align: "center"
    },
    { Header: "Code", accessor: "mastercode", align: "center" },
    { Header: "Name", accessor: "mastername" },
    { Header: "Remark", accessor: "remark" },
    {
        Header: "Status", accessor: (row, idx) => {
            return row.flag_active == "1" ? "Active" : "Non Active";
        },
    }
];

const MasterCode = () => {
    const { pageAccess } = PageInit();
    const [header, setHeader] = useState(tableHeader);
    const [data, setData] = useState([]);
    const [modal, setModal] = useState({ open: false, action: "Add", data: {} });
    const [viewDetail, setViewDetail] = useState({ open: false });

    // Set Header Table
    useEffect(() => {
        let columns = tableHeader;
        if (pageAccess.edits == true || pageAccess.deletes == true) {
            columns = [{
                Header: "Action",
                align: "center",
                sort: false,
                accessor: (row, idx) => {
                    return (<>
                        <DataTableBtn color={"success"} icon={"visibility"} sx={{
                            marginRight: (pageAccess.edits == true) && "2px !important"
                        }} onClick={(event) => showDetail(event, row)} />
                        <DataTableBtn color={"info"} icon={"edit"} sx={{
                            display: pageAccess.edits == false && "none",
                        }} onClick={(event) => editData(event, row)} />
                    </>);
                },
            }, ...columns];
        }
        setHeader([{
            id: "row_btn",
            Header: () => (<>
                <DataTableBtn icon={"add_circle"} sx={{
                    display: pageAccess.adds == false && "none",
                    marginRight: "2px !important",
                }} onClick={addData} />
                <DataTableBtn icon={"refresh"} color={"success"} sx={{
                    marginRight: pageAccess.downloads == true && "2px !important",
                }} onClick={() => reload()} />
            </>),
            columns: columns
        }]);
    }, [pageAccess]);

    const reload = (value = "") => {
        resApi({
            url: baseApi + "MasterData/MasterCode/ListCodeMaster",
            data: { mastercode: value },
            proccess: (result) => setData(result.data)
        });
    }

    useEffect(() => {
        reload();
    }, []);

    // function Add, Edit and Close in modal AddEditModal
    const addData = () => setModal({ open: true, action: "Add", data: {} });
    const editData = (e, data) => setModal({ open: true, action: "Edit", data: data });
    const closeModal = () => setModal({ ...modal, open: false });
    // function Show and Close in modal DetailCode
    const showDetail = (e, data) => { setViewDetail({ open: true, data: data }) };
    const closeDetail = () => setViewDetail({ ...viewDetail, open: false });

    return (<PageCanvas title={"Data Code Master"}>
        <DataTable table={{ columns: header, rows: data }} canSearch />
        <AddEditModal open={modal.open} onClose={closeModal} action={modal.action} data={modal.data} reload={reload} />
        <DetailCode open={viewDetail.open} onClose={closeDetail} parent={viewDetail.data} pageAccess={pageAccess} />
    </PageCanvas>);
}

const AddEditModal = ({ open, onClose, action, data, reload }) => {
    const [resolver, setResolver] = useState();
    const { handleSubmit, control, setValue, clearErrors } = useForm({
        defaultValues: data,
        resolver: yupResolver(resolver)
    });
    useEffect(() => {
        setResolver(yup.object({
            mastercode: action == "Add" ? yup.string().optional() : yup.string().required(),
            mastername: yup.string().required(),
            remark: yup.string(),
            flag_active: yup.bool()
        }));
    }, [action]);
    useEffect(() => {
        if (open === true) {
            clearErrors();
            setValue("action", action);
            setValue("mastercode", data.mastercode);
            setValue("mastername", data.mastername);
            setValue("remark", data.remark ? data.remark : "");
            setValue("flag_active", data.flag_active === undefined ? 1 : data.flag_active);
        }
    }, [open]);

    const save = (data) => {
        resApi({
            url: baseApi + "MasterData/MasterCode/SaveCodeMaster",
            data: data,
            proccess: (result) => {
                message({ message: result.message, alert: "success" });
                reload();
                onClose();
            }
        });
    }
    return (<ModalCanvas open={open} onClose={onClose} title={action + " Data Code Master"}>
        <MDBox component="form" role="form" noValidate>
            <MDBox mb={1}>
                <FormGroup name={"mastercode"} control={control} label="Master Code" disabled />
            </MDBox>
            <MDBox mb={1}>
                <FormGroup name={"mastername"} control={control} label="Master Name" required />
            </MDBox>
            <MDBox mb={1}>
                <FormGroup name={"remark"} control={control} label="Remark" />
            </MDBox>
            <MDBox mb={1}>
                <FormGroup name={"flag_active"} control={control} label="Status" type={"switch"} />
            </MDBox>
            <ModalButton action={action} onClose={onClose} onClick={handleSubmit(save)} />
        </MDBox>
    </ModalCanvas>);
}
AddEditModal.defaultProps = {
    open: false,
    action: "Add",
    data: {},

};
AddEditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    action: PropTypes.oneOf(["Add", "Edit"]),
    data: PropTypes.object,
    reload: PropTypes.func,
};


export default MasterCode;