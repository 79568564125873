import * as React from "react";
import {
  Avatar,
  Button,
  styled,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PageLayout } from "../../helper/PageFunction";
import FormGroup from "../../components/BaseElement/FormData";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState, useEffect } from "react";

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://digitalhasanah.co.id/">
        Digital Hasanah Indonesia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const defaultTheme = createTheme();

const Login = () => {
  PageLayout();
  const { handleSubmit, control, setValue, clearErrors } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(
      yup.object({
        email: yup.string().required(),
        password: yup.string().required(),
      })
    ),
  });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#060d0d"),
    backgroundColor: "#060d0d",
    "&:hover": {
      backgroundColor: "#122828",
    },
  }));

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://www.ruparupa.com/blog/wp-content/uploads/2021/08/rumah-mewah-putih.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#060d0d" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                // label="Email Address"
                autoComplete="email"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <EmailIcon sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 1 }} />
                  ),
                }}
              />
              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                name="password"
                type="password"
                label="password"
                placeholder="Password"
                id="password"
                autoComplete="current-password"
                InputProps={{
                  startAdornment: (
                    <LockIcon sx={{ color: "#060d0d", fontSize: "1.2em", marginRight: 1 }} />
                  ),
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    {...label}
                    sx={{
                      color: "#060d0d",
                      "&.Mui-checked": {
                        color: "#060d0d",
                      },
                    }}
                  />
                }
                label="Remember me"
              />
              <ColorButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit(onSubmit)}
                Sign
                In
              >Login</ColorButton>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/Register" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;
