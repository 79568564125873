import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setLayout, useLayoutUIController } from '../layout/context';

export const PageLayout = (page = 'Blank') => {
    const [controller, dispatch] = useLayoutUIController();
    const { layout } = controller;
    const { pathname } = useLocation();

    useEffect(() => {
        if (layout !== page) {
            setLayout(dispatch, page);
        }
    }, [pathname]);

    return { controller, dispatch, pathname };
}

export const PageInit = (page = 'Dashboard') => {
    const { controller, dispatch, pathname } = PageLayout(page);
    const [pageAccess, setPageAccess] = useState({
        views: true,
        adds: true, edits: true, deletes: true,
        prints: true, downloads: true,
    });

    return { pageAccess };
}