import { createContext, useContext, useMemo, useReducer } from "react";
import PropTypes from "prop-types";

const LayoutUI = createContext();
LayoutUI.displayName = "LayoutUIContext";

function reducer(state, action) {
    switch (action.type) {
        case "MINI_SIDENAV": return { ...state, miniSidenav: action.value };
        case "TRANSPARENT_NAVBAR": return { ...state, transparentNavbar: action.value };
        case "FIXED_NAVBAR": return { ...state, fixedNavbar: action.value };
        case "LAYOUT": return { ...state, layout: action.value };
        case "DARKMODE": return { ...state, darkMode: action.value };
        case "DATA_MAP": return { ...state, dataMap: action.value };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export function LayoutUIController({ children }) {
    const initialState = {
        miniSidenav: false,
        transparentNavbar: true,
        fixedNavbar: true,
        layout: "Blank",
        darkMode: false,
        dataMap: [],
    };
    const [controller, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
    return <LayoutUI.Provider value={value}>{children}</LayoutUI.Provider>;
}
LayoutUIController.propTypes = {
    children: PropTypes.node.isRequired,
};

export function useLayoutUIController() {
    const context = useContext(LayoutUI);
    if (!context) {
        throw new Error("useLayoutUIController should be used inside the LayoutUIController.");
    }
    return context;
}

export const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
export const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
export const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
export const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
export const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
export const setDataMap = (dispatch, value) => dispatch({ type: "DATA_MAP", value });
