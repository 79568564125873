import { useState } from "react";
import PropTypes from "prop-types";
import { setMiniSidenav, useLayoutUIController } from "../context";

import Sidenav from "./Sidenav";
import MDBox from "../../components/MDBox";
import AppNav from "./AppNav";

const DashboardLayout = ({ children }) => {
    const [controller, dispatch] = useLayoutUIController();
    const { miniSidenav } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    return (<>
        <Sidenav
            color={"info"}
            brandName={"eProperty Management"}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
        />
        <MDBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                p: 3,
                position: "relative",

                [breakpoints.up("xl")]: {
                    marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                    transition: transitions.create(["margin-left", "margin-right"], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
            })}
        >
            <AppNav />
            {children}
        </MDBox>
    </>);
}
DashboardLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
export default DashboardLayout;