import { FormControlLabel, Switch, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const FormGroup = ({ name, control, type, ...rest }) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, formState: { errors } }) => {
                if (type === "switch") {
                    return (<FormControlLabel control={<Switch checked={field.value == 1} />} {...field}  {...rest} />);
                } else {
                    return (
                        <TextField fullWidth {...rest}
                            // inputProps={{ readOnly: rest.readOnly }}
                            value={field.value ?? ""}
                            onChange={(event) => {
                                // console.log(event, field.value);
                                field.onChange(event);
                                rest.onChange ? rest.onChange(event) : null;
                            }}
                            type={type}
                            placeholder={rest.label}
                            error={errors[field.name] ? true : false}
                        // success={field.value.length > 0 && !errors[field.name]}
                        />);
                }
            }}
        />
    );
}
FormGroup.defaultProps = {
    type: "text"
}

FormGroup.propTypes = {
    name: PropTypes.string.isRequired,
    control: PropTypes.any.isRequired,
    type: PropTypes.oneOf(["text", "switch"]), //, "password", , "select2", "date", "number", "file"
};

export default FormGroup;