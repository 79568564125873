import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import { ModalButton, ModalCanvas } from "../../../components/BaseElement/Modal";
import { DataTableBtn } from "../../../components/BaseElement/Page";
import { message, resApi } from "../../../helper/function";
import { baseApi } from "../../../helper/constant";
import { makeStyles } from "@mui/styles";
import DataTable from "../../../components/BaseElement/TableV7";
import MDBox from "../../../components/MDBox";
import FormGroup from "../../../components/BaseElement/FormData";

const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-start !important"
    }
});

const tableHeader = [
    {
        Header: "No", accessor: (row, idx) => {
            return idx + 1;
        },
        align: "center"
    },
    { Header: "Code", accessor: "detailcode", align: "center" },
    { Header: "Name", accessor: "detailname" },
    { Header: "Remark", accessor: "remark" },
    {
        Header: "Status", accessor: (row, idx) => {
            return row.flag_active == "1" ? "Active" : "Non Active";
        },
    }
];
const DetailCode = ({ open, onClose, parent, pageAccess }) => {
    const classes = useStyles();
    const [header, setHeader] = useState(tableHeader);
    const [data, setData] = useState([]);
    const [mastercode, setMastercode] = useState("");
    let mastername = parent?.mastername ?? "";
    const [modal, setModal] = useState({ open: false, action: "Add", data: {} });

    // Set Header Table
    useEffect(() => {
        let columns = tableHeader;
        if (pageAccess.edits == true || pageAccess.deletes == true) {
            columns = [{
                Header: "Action",
                align: "center",
                sort: false,
                accessor: (row, idx) => {
                    return (<>
                        <DataTableBtn color={"info"} icon={"edit"} sx={{
                            display: pageAccess.edits == false && "none",
                        }} onClick={(event) => editData(event, row)} />
                    </>);
                },
            }, ...columns];
        }
        setHeader([{
            id: "row_btn",
            Header: () => (<>
                <DataTableBtn icon={"add_circle"} sx={{
                    display: pageAccess.adds == false && "none",
                    marginRight: "2px !important",
                }} onClick={addData} />
                <DataTableBtn icon={"refresh"} color={"success"} sx={{
                    marginRight: pageAccess.downloads == true && "2px !important",
                }} onClick={() => reload(mastercode)} />
            </>),
            columns: columns
        }]);
    }, [pageAccess, mastercode]);

    const reload = (value = "") => {
        resApi({
            url: baseApi + "MasterData/MasterCode/ListCodeDetail",
            data: { mastercode: value },
            proccess: (result) => setData(result.data)
        });
    }

    useEffect(() => {
        if (open === true && mastercode !== (parent?.mastercode ?? "")) {
            // console.log(parent.mastercode, "set mastercode");
            setMastercode(parent.mastercode);
            reload(parent.mastercode);
        }
    }, [open]);

    // function Add, Edit and Close in modal AddEditModal
    const addData = () => setModal({ open: true, action: "Add", data: { mastercode: mastercode, mastername: mastername } });
    const editData = (e, data) => setModal({ open: true, action: "Edit", data: { ...data, mastername: mastername } });
    const closeModal = () => setModal({ ...modal, open: false });

    return (<ModalCanvas open={open} onClose={onClose} title={"Data Code Detail (" + mastername + ")"}
        classes={{
            scrollPaper: classes.topScrollPaper,
        }}
        maxWidth="xl"
    >
        <DataTable table={{ columns: header, rows: data }} canSearch />
        <AddEditModal open={modal.open} onClose={closeModal} action={modal.action} data={modal.data} reload={reload} />
    </ModalCanvas>);
};
DetailCode.defaultProps = { open: false };
DetailCode.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    parent: PropTypes.object,
    pageAccess: PropTypes.object.isRequired,
};

// Modal AddEdit
const AddEditModal = ({ open, onClose, action, data, reload }) => {
    const [resolver, setResolver] = useState();
    const { handleSubmit, control, setValue, clearErrors } = useForm({
        defaultValues: data,
        resolver: yupResolver(resolver)
    });

    useEffect(() => {
        setResolver(yup.object({
            mastercode: yup.string().required(),
            detailcode: action == "Add" ? yup.string().optional() : yup.string().required(),
            detailname: yup.string().required(),
            remark: yup.string(),
            flag_active: yup.bool().required()
        }));
    }, [action]);

    useEffect(() => {
        if (open === true) {
            clearErrors();
            setValue("action", action);
            setValue("mastercode", data.mastercode);
            setValue("detailcode", data.detailcode);
            setValue("detailname", data.detailname);
            setValue("remark", data.remark ? data.remark : "");
            setValue("flag_active", data.flag_active === undefined ? 1 : data.flag_active);
        }
    }, [open]);

    const save = (data) => {
        resApi({
            url: baseApi + "MasterData/MasterCode/SaveCodeDetail",
            data: data,
            proccess: (result) => {
                message({ message: result.message, alert: "success" });
                reload(data.mastercode);
                onClose();
            }
        });
    }

    return (<ModalCanvas open={open} onClose={onClose} title={action + " Data Code Detail (" + data.mastername + ")"}>
        <MDBox component="form" role="form" noValidate>
            <MDBox mb={1}>
                <FormGroup name={"detailcode"} control={control} label="Detail Code" disabled />
            </MDBox>
            <MDBox mb={1}>
                <FormGroup name={"detailname"} control={control} label="Detail Name" required />
            </MDBox>
            <MDBox mb={1}>
                <FormGroup name={"remark"} control={control} label="Remark" />
            </MDBox>
            <MDBox mb={1}>
                <FormGroup name={"flag_active"} control={control} label="Status" type={"switch"} />
            </MDBox>
            <ModalButton action={action} onClose={onClose} onClick={handleSubmit(save)} />
        </MDBox>
    </ModalCanvas>);
};
AddEditModal.defaultProps = {
    open: false,
    action: "Add",
    data: {},

};
AddEditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    action: PropTypes.oneOf(["Add", "Edit"]),
    data: PropTypes.object,
    reload: PropTypes.func,
};

export default DetailCode;