import { createGlobalState } from "react-hooks-global-state";

const baseUrl = window.getPath();
export const basePath = baseUrl?.path;
export const baseApi = baseUrl?.api;

export const alertMessage = createGlobalState({
    open: false,
    title: "Information",
    content: "",
    color: "info"
});

export const loadScreen = createGlobalState({ open: false });