// Blank Page
import Login from "../pages/Login/LoginPages";
import Register from "../pages/Register";

// Dashboard Page
import Home from "../pages/Home";
// Master Data Page
import MasterCode from "../pages/MasterData/CodeMaster/MasterCode";

// Games Page
import TicTacToe from "../pages/Games/TicTacToe";

export const router = [
    { route: "/", component: <Home /> },
    { route: "/Login", component: <Login /> },
    { route: "/Register", component: <Register /> },
    { route: "/MasterData/CodeMaster", component: <MasterCode /> },
    {
        collapse: true,
        label: "Games",
        route: [
            { route: "/Games/TicTacToe", component: <TicTacToe /> }
        ]
    },
    { route: "*", component: <Home /> },
];

export const mapDefault = [
    {
        "url": "/Profile",
        "menuActive": ["cp_profile", "profile"],
        "breadcrumbs": ["Profile"],
    },
    {
        "url": "/ChangePassword",
        "menuActive": ["cp_profile", "change_password"],
        "breadcrumbs": ["Change Password"],
    },
];

export const menuSidenav = [
    {
        "menucode": "M00001",
        "menuname": "Dashboard",
        "menuurl": "/",
        "icon": "dashboard",
    },
    {
        "menucode": "M00002",
        "menuname": "Master Data",
        "icon": "app_registration",
        "child": [
            {
                "menucode": "M00003",
                "menuname": "Code Master",
                "menuurl": "/MasterData/CodeMaster",
            },
            {
                "menucode": "M00004",
                "menuname": "Master Project",
                "menuurl": "/MasterData/MasterProject",
            },
        ]
    },
    {
        "menucode": "M00098",
        "menuname": "Login",
        "menuurl": "/Login",
        "icon": "login"
    },
    {
        "menucode": "M00099",
        "menuname": "Register",
        "menuurl": "/Register",
        "icon": "how_to_reg"
    },
];
