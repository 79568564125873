import PropTypes from "prop-types";
import { useLayoutUIController } from "./context";
import { ListRoute } from "../routes";

import { CssBaseline, ThemeProvider } from "@mui/material";

import theme from "../assets/theme";
import DashboardLayout from "./template";
import { LoadingScreen, MessageNotice } from "../components/BaseElement/Page";
import { alertMessage, loadScreen } from "../helper/constant";

const Layout = () => {
    const [controller] = useLayoutUIController();
    const { layout } = controller;
    const BaseLayout = layout === "Dashboard" ? DashboardLayout : BlankLayout;
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LoadingScreen open={loadScreen.useGlobalState("open")[0]} />
            <MessageNotice
                open={alertMessage.useGlobalState("open")[0]}
                title={alertMessage.useGlobalState("title")[0]}
                content={alertMessage.useGlobalState("content")[0]}
                color={alertMessage.useGlobalState("color")[0]}
            />
            <BaseLayout>
                <ListRoute />
            </BaseLayout>
        </ThemeProvider>
    );
}

const BlankLayout = ({ children }) => {
    return (<>{children}</>);
}

BlankLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;