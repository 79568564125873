import * as React from "react";
import {
  Avatar,
  Button,
  styled,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Stack,
  colors,
  FormHelperText,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { PageLayout } from "../../helper/PageFunction";
import FormGroup from "../../components/BaseElement/FormData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Copyright = (props) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://digitalhasanah.co.id/">
        Digital Hasanah Indonesia
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const defaultTheme = createTheme();

const Register = () => {
  PageLayout();
  const onSubmit = (data) => console.log(data);
  const { handleSubmit, control, formState } = useForm({
    defaultValues: { regisname: "", password: "", picname: "", email: "", phone: "" },
    resolver: yupResolver(
      yup.object({
        regisname: yup.string().required(),
        password: yup.string().required(),
        picname: yup.string().required(),
        email: yup.string().required(),
        phone: yup.string().required(),
      })
    ),
  });
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#060d0d"),
    backgroundColor: "#060d0d",
    "&:hover": {
      backgroundColor: "#122828",
    },
  }));

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://image.archify.com/blog/ct9u9xn4.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#060d0d" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Register
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
              <Stack direction={"row"} spacing={2}>
                <FormGroup
                  control={control}
                  margin="normal"
                  required
                  fullWidth
                  id="regisname"
                  label="Register Name"
                  name="regisname"
                  autoFocus
                  // error={errors.register?.type === "required" && "Name is required"}
                />
                <FormGroup
                  control={control}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Stack>
              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                name="picname"
                label="Pic Name"
                type="text"
              />

              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email Address"
                autoComplete="email"
              />

              <FormGroup
                control={control}
                margin="normal"
                required
                fullWidth
                name="phone"
                label="Phone Number"
                type="number"
                id="phone"
                autoComplete="phone"
              />
              <ColorButton
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                sx={{ mt: 3, mb: 2 }}
              >
                Register Now
              </ColorButton>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Register;
