import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import MDBox from "../MDBox";
import MDButton from "../MDButton";

export const ModalCanvas = ({ children, maxWidth, open, onClose, title, ...rest }) => {
    return (<Dialog fullWidth maxWidth={maxWidth} {...rest} open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">{title}</DialogTitle>
        <IconButton aria-label="close" onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
            <Close />
        </IconButton>
        <DialogContent dividers>
            {children}
        </DialogContent>
    </Dialog>);
}
ModalCanvas.defaultProps = {
    open: false,
    maxWidth: "sm",
    title: "",
};
ModalCanvas.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    maxWidth: PropTypes.oneOf([false, "xs", "sm", "md", "lg", "xl"]),
};

export const ModalButton = ({ onClick, onClose, action }) => {
    return (<MDBox display="flex" justifyContent="flex-end">
        <MDButton variant="outlined" color="info" size="small" sx={{ marginRight: "2px !important" }} onClick={onClick}>{action === "Add" ? "Save" : "Update"}</MDButton>
        <MDButton variant="outlined" color="error" size="small" onClick={onClose}>Cancel</MDButton>
    </MDBox>);
}
ModalButton.defaultProps = {
    action: "Add",
    onClick: () => { },
    onClose: () => { },
};
ModalButton.propTypes = {
    action: PropTypes.oneOf(["Add", "Edit"]),
    onClick: PropTypes.func,
    onClose: PropTypes.func,
};
